import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import E_Data from "data/EVENT_DATA.json";
import P_Data from "data/P_DATA.json";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import ExamplesNavbar from "componentsLogos/Navbars/P-NavBar";
import LandingPageHeader from "componentsLogos/Headers/LandingPageHeader.js";
import DefaultFooter from "componentsLogos/Footers/DefaultFooter";
//React Share
import ShareB from "views/index-sectionsLogos/ShareButton";
//ImageGallerie

import ToTheTop from "views/index-sectionsLogos/ButtonToTop";

function LandingPage() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const location = useLocation();
  const { title } = location.state;

  const [text, setText] = useState(P_Data.slice(0, 500));
  const displayText = text
    .filter((P) => P.paragrapheOF === title)
    .map((P) => {
      return (
        <div>
          <h4>{P.title}</h4>
          <p className="EV-P">{P.text}</p>
        </div>
      );
    });

  return (
    <>
      <ExamplesNavbar url="/evenements" type=" ÉVÉNEMENTS" />
      <div className="wrapper">
        <LandingPageHeader />
        {E_Data.filter((user) => user.title === title).map((user) => {
          return (
            <Container fluid className="EV-Page">
              <Row>
                <Col
                  className="ml-auto mr-auto text-center"
                  md="6"
                  style={{ paddingBottom: "70px" }}
                >
                  <h2
                    style={{ paddingBottom: "10px", paddingTop: "30px" }}
                    className="title"
                  >
                    {user.title}
                  </h2>
                  {displayText}
                </Col>
                <Col md="6">
                  <Row style={{ justifyContent: "center" }}>
                    <img
                      height="700px"
                      width="500px"
                      style={{
                        marginTop: "-7.3vw",
                      }}
                      src={require(`assets/img/${user.posterURL}`).default}
                      alt="E-poster"
                    />
                  </Row>
                  <div
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "15px",
                      display: "flex",
                      textAlign: "center",
                    }}
                  >
                    <ShareB
                      Siz="45px"
                      S_url="www.logos.ma/evenements"
                      position="center"
                    />
                  </div>
                </Col>
              </Row>

              <Container className="EV-page-Icon-con">
                <Row>
                  <Col md="4">
                    <div className="btnE-E">
                      <i class="fas fa-clock"></i>
                      <h5>
                        {user.date}
                        {"  "} | {"  "}
                        {user.e_date}
                      </h5>
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="btnE-E">
                      <i class="fas fa-map-marker-alt"></i>
                      <h5>{user.e_lieu}</h5>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="btnE-E">
                      <i class="fas fa-mobile-alt"></i>
                      <h5>{user.phone}</h5>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Container>
          );
        })}

        <ToTheTop />
        <DefaultFooter />
      </div>
    </>
  );
}

export default LandingPage;
