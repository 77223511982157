import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

//ShareButton
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
const ShareButton = (props) => {
  return (
    <Container
      fluid
      style={{
        paddingTop: "20px",
        paddingBottom: "20px",
        display: "flex",
        justifyContent: props.position,
      }}
    >
      <Row style={{ display: "flex" }}>
        <FacebookShareButton url={props.S_url}>
          <FacebookIcon size={props.Siz} />
        </FacebookShareButton>
        <FacebookMessengerShareButton url={props.S_url}>
          <FacebookMessengerIcon size={props.Siz} />
        </FacebookMessengerShareButton>
        <WhatsappShareButton url={props.S_url}>
          <WhatsappIcon size={props.Siz} />
        </WhatsappShareButton>
        <TwitterShareButton url={props.S_url}>
          <TwitterIcon size={props.Siz} />
        </TwitterShareButton>
        <LinkedinShareButton url={props.S_url}>
          <LinkedinIcon size={props.Siz} />
        </LinkedinShareButton>
        <TelegramShareButton url={props.S_url}>
          <TelegramIcon size={props.Siz} />
        </TelegramShareButton>
        <EmailShareButton url={props.S_url}>
          <EmailIcon size={props.Siz} />
        </EmailShareButton>
      </Row>
    </Container>
  );
};

export default ShareButton;
