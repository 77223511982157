import React from "react";
import { Link } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";
// reactstrap components

import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={" fixed-top " + navbarColor} expand="lg" color="white">
        {document.documentElement.scrollTop > 400 ||
        document.body.scrollTop > 400 ? (
          <Container>
            <div className="navbar-translate">
              <NavbarBrand tag={Link} to={"/"} id="navbar-brand">
                <div>
                  <img
                    style={{
                      width: "25px",
                    }}
                    alt="..."
                    src={require("assets/img/Logos.png").default}
                  ></img>
                </div>
              </NavbarBrand>
              <UncontrolledTooltip target="#navbar-brand">
                LOGOS
                <br /> PENSER AUTREMENT
              </UncontrolledTooltip>
              <button
                className="navbar-toggler navbar-toggler"
                onClick={() => {
                  document.documentElement.classList.toggle("nav-open");
                  setCollapseOpen(!collapseOpen);
                }}
                aria-expanded={collapseOpen}
                type="button"
              >
                <span className="navbar-toggler-bar top-bar"></span>
                <span className="navbar-toggler-bar middle-bar"></span>
                <span className="navbar-toggler-bar bottom-bar"></span>
              </button>
            </div>
            <Collapse
              className="justify-content-end"
              isOpen={collapseOpen}
              navbar
            >
              <Nav navbar>
                <li className="Nav_item">
                  <NavLink to="/presentation" tag={Link}>
                    <i class="fas fa-book-reader"></i>
                    <p>Présentation</p>
                  </NavLink>
                </li>

                <li className="Nav_item">
                  <NavLink
                    tag={LinkScroll}
                    activeClass="active"
                    to="Events-section"
                    spy={true}
                    smooth={true}
                    duration={1500}
                  >
                    <i class="fas fa-calendar-day"></i>
                    <p>Événements</p>
                  </NavLink>
                </li>

                <li className="Nav_item">
                  <NavLink
                    tag={LinkScroll}
                    activeClass="active"
                    to="Publication-section"
                    spy={true}
                    smooth={true}
                    duration={1500}
                  >
                    <i class="fas fa-pen-alt"></i>
                    <p>Publications</p>
                  </NavLink>
                </li>

                <li className="Nav_item">
                  <NavLink
                    tag={LinkScroll}
                    activeClass="active"
                    to="Media-section"
                    spy={true}
                    smooth={true}
                    duration={1500}
                  >
                    <i class="fas fa-camera"></i>
                    <p>Media</p>
                  </NavLink>
                </li>

                <li className="Nav_item">
                  <NavLink
                    tag={LinkScroll}
                    activeClass="active"
                    to="ContactUs-section"
                    spy={true}
                    smooth={true}
                    duration={1500}
                  >
                    <i class="fas fa-envelope"></i>
                    <p>Contactez-nous</p>
                  </NavLink>
                </li>
              </Nav>
            </Collapse>
          </Container>
        ) : null}
      </Navbar>
    </>
  );
}

export default IndexNavbar;
