import React, { useState, Suspense } from "react";
import JsonData from "data/EVENT_DATA.json";
import ReactPaginate from "react-paginate";
import Load from "../index-sectionsLogos/loading";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import "assets/css/logosStyle.css";

//Event Component
import EventComp from "../index-sectionsLogos/EventComponent";
// core components
import Video from "../index-sectionsLogos/Videos";
import ExamplesNavbar from "componentsLogos/Navbars/ExtraNavbar";
import LandingPageHeader from "componentsLogos/Headers/LandingPageHeader.js";
import DefaultFooter from "componentsLogos/Footers/DefaultFooter.js";
import SearchField from "views/index-sectionsLogos/SearchField";
import ToTheTop from "views/index-sectionsLogos/ButtonToTop";
import TitlePage from "views/index-sectionsLogos/PageTitle";
function LandingPage() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const [users, setUsers] = useState(JsonData.slice(0, 50));
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 6;
  const pagesVisited = pageNumber * usersPerPage;

  const displayUsers = users
    .slice(pagesVisited, pagesVisited + usersPerPage)
    .map((user) => {
      return (
        <Col md="4" lg="4" style={{ dispplay: "flex" }}>
          <EventComp
            Img={require(`assets/img/${user.posterURL}`).default}
            title={user.title}
            description={user.description}
            link={user.link}
            date={user.date}
            lieu={user.lieu}
          />
        </Col>
      );
    });
  const pageCount = Math.ceil(users.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <>
      <Suspense fallback={<Load />}>
        <div className="App">
          <ExamplesNavbar />
          <LandingPageHeader />
          <Video src={require("assets/videos/E-V.mp4").default} />
          <TitlePage styles="T-title" title="ÉVÉNEMENTS DU " logos="LOGOS" />
          <SearchField placeholder="Vous Cherchez?" data={JsonData} />
          <div className="section section-team text-center">
            <Container>
              <Row>
                <Suspense fallback={<Load />}>{displayUsers}</Suspense>
              </Row>
            </Container>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
        <ToTheTop />
        <DefaultFooter />
      </Suspense>
    </>
  );
}

export default LandingPage;
