import React, { Suspense } from "react";
import Zoom from "react-reveal/Zoom";
import Load from "./index-sectionsLogos/loading";
import IndexNavbar from "componentsLogos/Navbars/IndexNavbar.js";
import IndexHeader from "componentsLogos/Headers/IndexHeader.js";
import DarkFooter from "componentsLogos/Footers/DefaultFooter";
import ToTheTop from "views/index-sectionsLogos/ButtonToTop";
// sections for this page
const Video = React.lazy(() => import("./index-sectionsLogos/Videos"));
const Event = React.lazy(() => import("./index-sectionsLogos/Events"));
const Media = React.lazy(() => import("./index-sectionsLogos/Media"));
const TitlePage = React.lazy(() =>
  import("views/index-sectionsLogos/HomeTitle")
);
//Component
const Publication = React.lazy(() =>
  import("./index-sectionsLogos/Publication")
);
const Download = React.lazy(() =>
  import("./index-sectionsLogos/ContactUs2.js")
);
const ContactUs = React.lazy(() => import("./index-sectionsLogos/ContactUs"));

const Partners = React.lazy(() =>
  import("views/index-sectionsLogos/PartnerShip")
);

function Index() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    //test
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <Suspense fallback={<Load />}>
        <IndexNavbar />
        <div className="wrapper">
          <IndexHeader />
          <Zoom>
            <Suspense fallback={<Load />}>
              <Video src={require("assets/videos/P-V.mp4").default} />
            </Suspense>
            <Suspense fallback={<Load />}>
              <TitlePage title="BIENVENUE SUR " />
            </Suspense>
            <Suspense fallback={<Load />}>
              <Event />
            </Suspense>
            <Suspense fallback={<Load />}>
              <Publication />
            </Suspense>
            <Suspense fallback={<Load />}>
              <Media />
            </Suspense>
            <Suspense fallback={<Load />}>
              <ContactUs />
            </Suspense>
            <Suspense fallback={<Load />}>
              <Partners />
            </Suspense>
            <Suspense fallback={<Load />}>
              <Download />
            </Suspense>
          </Zoom>
          <ToTheTop />
          <DarkFooter />
        </div>
      </Suspense>
    </>
  );
}

export default Index;
