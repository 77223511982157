import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
// React Router
import { Link } from "react-router-dom";
//Ant Lib
import { Image } from "antd";
// reactstrap components
import { Button } from "reactstrap";
// React Icons
import { FaMapMarkerAlt, FaClock } from "react-icons/fa";
import "assets/css/logosStyle.css";
const EventComponent = (props) => {
  return (
    <div
      style={{
        paddingBottom: "8%",
      }}
      className="team-player"
    >
      <Image
        className="img-fluid img-raised"
        style={{ height: "425px", width: "325px" }}
        src={props.Img}
      />

      <h4 className="E-title">{props.title}</h4>
      <p className="category text-info E">Événement</p>
      <p className="E-description">{props.description}</p>
      <Button
        style={{ marginTop: "50px" }}
        block
        className="btn-round E"
        color="info"
        to={{ pathname: props.link, state: { title: props.title } }}
        tag={Link}
        size="lg"
      >
        Plus d'information
      </Button>
      <div className="pub-divI">
        <div className="pub-divE">
          <FaClock color="#1aa5e1" size="14px" />
          <h6 style={{ marginLeft: "5px" }}> {props.date}</h6>
        </div>
        <div className="pub-divE">
          <FaMapMarkerAlt color="#1aa5e1" size="14px" />
          <h6 style={{ marginLeft: "5px" }}> {props.lieu} </h6>
        </div>
      </div>
    </div>
  );
};

export default EventComponent;
