import React, { useState, Suspense } from "react";
import I_Data from "data/IMAGES_DATA.json";
import V_Data from "data/VIDEOS_DATA.json";
import Load from "../index-sectionsLogos/loading";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";
import { Image, Divider } from "antd";
// core components
import ExamplesNavbar from "componentsLogos/Navbars/ExtraNavbar";
import LandingPageHeader from "componentsLogos/Headers/LandingPageHeader.js";
import DefaultFooter from "componentsLogos/Footers/DefaultFooter.js";
import TitlePage from "views/index-sectionsLogos/PageTitle";
import { Player, BigPlayButton } from "video-react";
import ShareB from "views/index-sectionsLogos/ShareButton";
import ToTheTop from "views/index-sectionsLogos/ButtonToTop";
function LandingPage() {
  const [numberI, setnumberI] = useState(9);
  const [numberV, setnumberV] = useState(3);
  const [images, setImages] = useState(I_Data.slice(0, 500));
  const [videos, setVideos] = useState(V_Data.slice(0, 100));
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const displayImages = images.slice(0, numberI).map((IMG) => {
    return (
      <Col md="4" style={{ dispplay: "flex", paddingTop: "20px" }}>
        <Image
          width={"100%"}
          height={250}
          src={require(`assets/img/${IMG.imageURL}`).default}
          alt={IMG.title}
          className="media-img"
        />
        <div className="Med-divI">
          <button className="Med-buttE">
            <i color="#1aa5e1" class="fas fa-heart"></i>
            <h6 style={{ marginLeft: "5px" }}>0 </h6>
          </button>
          <button className="Med-buttE">
            <i color="#1aa5e1" class="fas fa-comments"></i>
            <h6 style={{ marginLeft: "5px" }}>0 </h6>
          </button>
        </div>
      </Col>
    );
  });
  const displayVideos = videos.slice(0, numberV).map((VID) => {
    return (
      <Col md="4" style={{ dispplay: "flex", paddingTop: "20px" }}>
        <Player src={require(`assets/videos/${VID.videoURL}`).default}>
          <BigPlayButton position="center" />
        </Player>
        <p
          style={{
            textAlign: "center",
            fontWeight: "400",
            paddingTop: "10px",
          }}
        >
          {VID.title}
        </p>
      </Col>
    );
  });
  const handleShowMoreIMG = () => {
    setnumberI(numberI + 6);
  };
  const handleShowMoreVID = () => {
    setnumberV(numberV + 3);
  };
  return (
    <>
      <Suspense fallback={<Load />}>
        <ExamplesNavbar />
        <div className="wrapper">
          <LandingPageHeader />
          <TitlePage styles="H-title" title={"MEDIA DU"} logos="LOGOS" />
        </div>
        <div className="section-story-overview">
          <Container fluid>
            <Container fluid style={{ marginTop: "-140px" }}>
              <div className="section-story-overview">
                <Row>
                  <Col md="1"> </Col>
                  <Col md="5">
                    <div
                      className="image-container image-left media"
                      style={{
                        backgroundImage:
                          "url(" +
                          require("assets/img/LOGOS2.jpg").default +
                          ")",
                      }}
                    ></div>
                  </Col>
                  <Col md="5">
                    <div
                      className="image-container image-right media"
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/img2.jpg").default + ")",
                      }}
                    ></div>
                  </Col>
                  <Col md="1"></Col>
                </Row>
              </div>
            </Container>
            <div className="section-story-overview">
              <Container fluid>
                <Row>
                  <Col md="1"> </Col>
                  <Col md="5">
                    <div className="div-img">
                      <div
                        className="image-container image-right row2"
                        style={{
                          backgroundImage:
                            "url(" +
                            require("assets/img/img26.jpg").default +
                            ")",
                        }}
                      ></div>
                    </div>
                  </Col>
                  <Col md="5">
                    <div className="div-img2">
                      <div
                        className="image-container image-left row2"
                        style={{
                          backgroundImage:
                            "url(" +
                            require("assets/img/img5.jpg").default +
                            ")",
                        }}
                      ></div>
                    </div>
                  </Col>
                  <Col md="1"></Col>
                </Row>
              </Container>
            </div>
            <Row>
              <Col md="12">
                <div className="sheraM">
                  <ShareB
                    Siz="55px"
                    S_url="www.logos.ma/medias"
                    position="center"
                  />
                </div>
              </Col>
            </Row>
            <Container style={{ paddingBottom: "10px", marginTop: "20px" }}>
              <Row>
                <Col md="12">
                  <Divider orientationMargin="0" orientation="left">
                    <TitlePage
                      styles="H-title mini"
                      title="IMAGES DU "
                      logos="LOGOS"
                    />
                  </Divider>
                </Col>
              </Row>
              <Row className="afterD">
                <Suspense fallback={<Load />}>
                  <Image.PreviewGroup>{displayImages}</Image.PreviewGroup>
                </Suspense>
              </Row>
              <Row>
                <Col md="12">
                  <div className="send-button media">
                    <Button
                      className="btn-round media"
                      color="info"
                      size="lg"
                      onClick={handleShowMoreIMG}
                    >
                      <i class="fas fa-sync fa-spin"></i>
                      Voir Plus ...
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container className="container-vid">
              <Row>
                <Col md="12">
                  <Divider orientationMargin="0" orientation="left">
                    <TitlePage
                      styles="H-title mini"
                      title="VIDEOS DU "
                      logos="LOGOS"
                    />
                  </Divider>
                </Col>
              </Row>
              <Row className="afterD">
                <Col md="1"></Col>
                <Col md="5">
                  <Player src={require("assets/videos/P-V.mp4").default}>
                    <BigPlayButton position="center" />
                  </Player>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "450",
                      paddingTop: "10px",
                    }}
                  >
                    Le LOGOS, c'est quoi ? Avec M. Tariq Akdim
                  </p>
                </Col>
                <Col md="5">
                  <Player src={require("assets/videos/E-V.mp4").default}>
                    <BigPlayButton position="center" />
                  </Player>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "450",
                      paddingTop: "10px",
                    }}
                  >
                    LOGOS-Bassinde Réflexion pour Penser Autrement
                  </p>
                </Col>
                <Col md="1"></Col>
              </Row>
              <Row>
                <Suspense fallback={<Load />}>{displayVideos}</Suspense>
              </Row>
              <Row>
                <Col md="12">
                  <div className="send-button media">
                    <Button
                      className="btn-round media"
                      color="info"
                      size="lg"
                      onClick={handleShowMoreVID}
                    >
                      <i class="fas fa-sync fa-spin"></i>
                      Voir Plus ...
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </div>
        <ToTheTop />
        <DefaultFooter />
      </Suspense>
    </>
  );
}

export default LandingPage;
