/*eslint-disable*/
import React from "react";
import "assets/css/logosStyle.css";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// react ROUTER
import { NavLink } from "react-router-dom";

function TransparentFooter() {
  return (
    <Container className="F-container" fluid>
      <Row>
        <Col className="Col-footer" md="6">
          <nav>
            <NavLink to="/presentation">PRÉSENTATION</NavLink>

            <NavLink to="/evenements">ÉVÉNEMENTS</NavLink>

            <NavLink to="/publications">PUBLICATIONS</NavLink>

            <NavLink to="/medias">MEDIA</NavLink>
          </nav>
        </Col>
        <Col md="6">
          <div className="copyright" id="copyright">
            <p style={{ color: "black", fontWeight: "400" }}>
              © {new Date().getFullYear()} LOGOS Tous Droits Réservés , Désigner{" "}
              {" & "} Coder Par{" "}
              <a
                href="https://www.linkedin.com/in/marouane-shaimi-555782150/"
                target="_blank"
              >
                MAROUANE SHAIMI
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default TransparentFooter;
