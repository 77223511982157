import React, { useState, Suspense } from "react";
import { useLocation } from "react-router-dom";
import P_Data from "data/P_DATA.json";
import Load from "../index-sectionsLogos/loading";
import ShareB from "views/index-sectionsLogos/ShareButton";
import { FaUserAlt, FaClock } from "react-icons/fa";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components
import ExamplesNavbar from "componentsLogos/Navbars/P-NavBar";
import LandingPageHeader from "componentsLogos/Headers/LandingPageHeader.js";
import DefaultFooter from "componentsLogos/Footers/DefaultFooter";
import ToTheTop from "views/index-sectionsLogos/ButtonToTop";
function LandingPage() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const location = useLocation();
  const { title, date, auteur } = location.state;
  const [images, setImages] = useState(P_Data.slice(0, 500));
  const displayImages = images
    .filter((P) => P.paragrapheOF === title)
    .map((P) => {
      return (
        <div>
          <h4 style={{ fontWeight: "bold" }}>{P.title}</h4>
          <p
            style={{ textAlign: "justify", fontWeight: "400", color: "black" }}
          >
            {P.text}
          </p>
        </div>
      );
    });

  return (
    <>
      <Suspense fallback={<Load />}>
        <ExamplesNavbar url="/publications" type="PUBLICATIONS" />
        <div className="wrapper">
          <LandingPageHeader />
          <div className="section section-about-us">
            <Container fluid>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="12">
                  <h2 className="title"> {title} </h2>
                  <h4
                    style={{ padding: "3%" }}
                    className="category text-info pub"
                  >
                    PUBLICATION DU LOGOS
                  </h4>
                </Col>
              </Row>

              <div className="section-story-overview">
                <Row>
                  <Col md="1"> </Col>
                  <Col md="5">
                    <div
                      className="image-container image-left"
                      style={{
                        backgroundImage:
                          "url(" +
                          require("assets/img/img19.jpg").default +
                          ")",
                      }}
                    >
                      <p
                        style={{
                          width: "500px",
                          height: "300px",
                          backgroundImage:
                            "url(" +
                            require("assets/img/img15.jpg").default +
                            ")",
                          backgroundSize: "100% ",
                          backgroundRepeat: "no-repeat",
                        }}
                        className="blockquote blockquote-info"
                      ></p>
                    </div>
                    <div
                      className="image-container"
                      style={{
                        backgroundImage:
                          "url(" +
                          require("assets/img/img14.jpg").default +
                          ")",
                      }}
                    ></div>
                  </Col>
                  <Col md="5">
                    <div
                      className="image-container image-right pub"
                      style={{
                        backgroundImage:
                          "url(" +
                          require("assets/img/img24.jpg").default +
                          ")",
                      }}
                    ></div>
                    <div style={{ paddingBottom: "10px" }}>
                      <ShareB
                        Siz="45px"
                        S_url="www.logos.ma/Publications"
                        position="start"
                      />
                      <h6
                        style={{
                          color: "gray",
                          display: "flex",
                        }}
                      >
                        <div>
                          <FaClock style={{ marginTop: "-2px" }} />
                          {"-" + date + "  "}
                        </div>
                        /
                        <div>
                          <FaUserAlt style={{ marginTop: "-2px" }} />
                          {"-" + auteur}
                        </div>
                      </h6>
                    </div>
                    {displayImages}

                    <h6 className="writer">
                      Tariq AKDIM <br /> Socio-Économiste, Chercheur Et
                      Chroniqueur, Président Du LOGOS
                    </h6>
                  </Col>
                  <Col md="1"></Col>
                </Row>
              </div>
            </Container>
          </div>
          <ToTheTop />
          <DefaultFooter />
        </div>
      </Suspense>
    </>
  );
}

export default LandingPage;
