import React from "react";
import { Container, Row, Col } from "reactstrap";
const PageTitle = (props) => {
  return (
    <Container>
      <Row className="section section-team text-center">
        <Col>
          <p className={props.styles}>
            {props.title}
            <h2>{props.logos}</h2>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PageTitle;
