import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
import "antd/dist/antd.css";
import "video-react/dist/video-react.css";

//Views
import Index from "views/index";
import MediaPage from "views/publicViews/MediasPage";
import PresentationPage from "views/publicViews/PresentationPage";
import EvenementPage from "views/publicViews/EvenementPage";
import PublicationPage from "views/publicViews/PublicationPage";
import P_PUB from "views/publicViews/P-PUBLICATION";
import P_EVENT from "views/publicViews/P-EVENT";

ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route path="/" exact render={(props) => <Index {...props} />} />
      <Route path="/medias" render={(props) => <MediaPage {...props} />} />
      <Route
        path="/presentation"
        render={(props) => <PresentationPage {...props} />}
      />
      <Route
        path="/publications"
        render={(props) => <PublicationPage {...props} />}
      />
      <Route
        path="/evenements"
        render={(props) => <EvenementPage {...props} />}
      />

      <Route path="/Publication" render={(props) => <P_PUB {...props} />} />

      <Route path="/event" render={(props) => <P_EVENT {...props} />} />
      <Redirect to="/" />
      <Redirect from="/" to="/" />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
