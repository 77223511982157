import React, { useState } from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import P_Data from "data/P_DATA.json";
// core components
import ExamplesNavbar from "componentsLogos/Navbars/ExtraNavbar";
import LandingPageHeader from "componentsLogos/Headers/LandingPageHeader.js";
import DefaultFooter from "componentsLogos/Footers/DefaultFooter";
import ToTheTop from "views/index-sectionsLogos/ButtonToTop";
//React Share
import ShareB from "views/index-sectionsLogos/ShareButton";

function LandingPage() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const [images, setImages] = useState(P_Data.slice(0, 50));
  const displayPub = images
    .filter((P) => P.paragrapheOF === "presentation")
    .map((P) => {
      return (
        <div>
          <h4 style={{ fontWeight: "bold" }}>{P.title}</h4>
          <p
            style={{ textAlign: "justify", fontWeight: "400", color: "black" }}
          >
            {P.text}
          </p>
        </div>
      );
    });
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container fluid>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h2 style={{ color: "#1aa5e1" }} className="title">
                  Qui Sommes-Nous ?
                </h2>
                <h4 className="sous-title">
                  LOGOS - Organisation Non Gouvernementale (ONG) - <br /> Est
                  Une assise Humaine , Où "penser" n'est pas un fait élitiste ,
                  Mais Un Droit De Citoyenneté Démocratiquement Fondé.
                </h4>
              </Col>
            </Row>

            <div className="section-story-overview">
              <Row>
                <Col md="1"> </Col>
                <Col md="5">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/LOGOS2.jpg").default + ")",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "justify",
                        width: "350px",
                        color: "darkblue",
                        fontSize: "15px ",
                        fontWeight: "bold",
                      }}
                      className="blockquote blockquote-info p"
                    >
                      LOGOS Est Une Invitation Pour Penser Autrement Sur Notre
                      Société, Nos Espaces Et Notre Temps. Il Se Veut Un Club
                      Scientifique à Caractère Culturel Qui Privilégie L'esprit
                      De La Raison, De La Science Et Du Savoir. C'est Un Espace
                      De Rencontre De Toutes Les Idées Qui Se Confrontent Et Non
                      Qui S'affrontent.
                      <br />
                      <br />
                      <small
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        - TARIQ AKDIM -{" "}
                      </small>
                    </p>
                  </div>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/img63.jpg").default + ")",
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/LOGOS3.jpg").default + ")",
                    }}
                  ></div>
                  <div style={{ paddingBottom: "10px" }}>
                    <ShareB Siz="45px" S_url="www.logos.ma/presentation" />
                  </div>
                  {displayPub}
                </Col>
                <Col md="1"></Col>
              </Row>
            </div>
          </Container>
        </div>
        <ToTheTop />
        <DefaultFooter />
      </div>
    </>
  );
}

export default LandingPage;
