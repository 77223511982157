import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
// React Router
import { Link } from "react-router-dom";

// reactstrap components
import { Button } from "reactstrap";
// React Icons
import { FaUserAlt, FaClock } from "react-icons/fa";
import "assets/css/logosStyle.css";
const PubComponent = (props) => {
  return (
    <div
      style={{
        paddingBottom: "8%",
      }}
      className="team-player"
    >
      <LazyLoadImage
        className="rounded-circle img-fluid img-raised"
        alt="img1"
        effect="blur"
        src={props.Img}
      />

      <h4 className="E-title">{props.title}</h4>
      <p className="category text-info">Publication</p>
      <p className="E-description">{props.description}</p>
      <Button
        style={{ marginTop: "50px" }}
        block
        className="btn-round E"
        color="info"
        to={{
          pathname: props.link,
          state: { title: props.title, date: props.date, auteur: props.auteur },
        }}
        tag={Link}
        size="lg"
      >
        Plus d'information
      </Button>
      <div className="pub-divI">
        <div className="pub-divE">
          <FaClock color="#1aa5e1" size="14px" />
          <h6 style={{ marginLeft: "5px" }}> {props.date}</h6>
        </div>
        <div className="pub-divE">
          <FaUserAlt color="#1aa5e1" size="14px" />
          <h6 style={{ marginLeft: "5px" }}> {props.auteur} </h6>
        </div>
      </div>
    </div>
  );
};

export default PubComponent;
