import React from "react";
import { Row, Container } from "reactstrap";
import { Player, BigPlayButton } from "video-react";
import "assets/css/logos.css";

function CarouselMain(props) {
  return (
    <>
      <Container className="section section-images V">
        <Row className="V-margin">
          <Player src={props.src}>
            <BigPlayButton position="center" />
          </Player>
        </Row>
      </Container>
    </>
  );
}

export default CarouselMain;
