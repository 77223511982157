import React from "react";
import { Container, Row, Col } from "reactstrap";
import ReactLoading from "react-loading";
const PageTitle = () => {
  return (
    <Container>
      <Row>
        <Col className="d-flex justify-content-center" md="12">
          <div>
            <ReactLoading
              type={"bars"}
              color={"#1aa5e1"}
              height={300}
              width={200}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PageTitle;
